const VAT_APPORT_MIN = 0;
const VAT_DECOUVERT_MIN = 5000;
const VAT_TAUX_APPORT_BASE = 0.15;
const TRANCHE_APPORT = 500;
const LOA_APPORT_MIN = 0;
const LOA_DECOUVERT_MIN = 0.35;
const LOA_TAUX_APPORT_BASE = 0.15;
const LOA_VR_BASE = 0.15;
const LOA_VR_1 = 0.01;
const LOA_VR_10 = 0.1;
const LOA_GARANTIE_BASE = 0;

const VIAXEL_VN = 'VN';
const VIAXEL_VO = 'VO';
const GENRE_VP = 'VP';

const sixMonths = 15778800;
const fiveYears = 157788000;
const fourYears = 126144000;
const eightYears = 252288000;
const sevenYears = 220903200;

const segmentsViaxel = ['B', 'M', 'H'];

const BASE_KILOMETRIQUE = 10000;

const TAUX_TVA = 0.2;

const ORIGIN_DEFAULT = 1;
const ORIGIN_STARTERRE = 2;
const ORIGIN_SITEPRO = 3;
const ORIGIN_SITEPERSO = 4;
const ORIGIN_PICKUP = 5;

const VALEUR_OPTION_SECURICAR = {
  "diesel" : {
    "VN": {
      "garantieConstructeur": {
        "24": 14,
        "36": 11
      }
    },
    "VO": {
      "nbMoisGarantieRestante" : {
        ">12": { //garantie restante superieure à 12 mois
          "ageVehicule" : {
            "<5": { // age du vehicule
              "puissanceDin" : {
                "<80" : 17, // puissance Din
                "<100" : 17,
                "<150" : 29,
                "<200" : 32,
                "<450" : 43
              }
            },
            ">=5" : {
              "puissanceDin" : {
                "<80": 23,
                "<100": 25,
                "<150": 46,
                "<200": 52,
                "<450": 72
              }
            }
          }
        },
        "<12": { //garantie restante inferieure à 12 mois
          "puissanceDin" : {
            "<80" : 21,
            "<100" : 23,
            "<150" : 42,
            "<200" : 54,
            "<450" : 70
          }
        }
      }
    }
  },
  "essence" : {
    "VN": {
      "garantieConstructeur": {
        "24": 11,
        "36": 9
      }
    },
    "VO": {
      "nbMoisGarantieRestante" : {
        ">12": { //garantie restante superieure à 12 mois
          "ageVehicule" : {
            "<5": { // age du vehicule
              "puissanceDin" : {
                "<80": 15, // puissance Din
                "<100": 16,
                "<150": 24,
                "<200": 27,
                "<450": 35
              }
            },
            ">=5": {
              "puissanceDin" : {
                "<80": 19,
                "<100": 22,
                "<150": 38,
                "<200": 41,
                "<450": 57
              }
            }
          }
        },
        "<12": { //garantie restante inferieure à 12 mois
          "puissanceDin" : {
            "<80": 18,
            "<100": 19,
            "<150": 33,
            "<200": 43,
            "<450": 56
          }
        }
      }
    }
  },
  "electrique" : {
    "VN": {
      "garantieConstructeur": {
        "24": 10,
        "36": 7
      }
    },
    "VO": {
      "nbMoisGarantieRestante": {
        ">12": { //garantie restante superieure à 12 mois
          "ageVehicule" : {
            "<5": { // age du vehicule
              "puissanceDin" : {
                "<80": 13, // puissance Din
                "<100": 14,
                "<150": 20,
                "<200": 22,
                "<450": 26
              }
            },
            ">=5": {
              "puissanceDin" : {
                "<80": 22,
                "<100": 26,
                "<150": 40,
                "<200": 46,
                "<450": 54
              }
            }
          }
        },
        "<12": { //garantie restante inferieure à 12 mois
          "puissanceDin" : {
            "<80": 15,
            "<100": 17,
            "<150": 27,
            "<200": 34,
            "<450": 39
          }
        }
      }
    }
  }
};

const VALEUR_OPTION_S3 =  {
  "diesel" : {
    "VN": {
      "roulage": {
        "10000" : {
          "puissanceDin" : {
            "<80" : 41,
            "<100" : 41,
            "<150" : 40,
            "<200" : 49,
            "<400" : 64
          }
        },
        "20000" : {
          "puissanceDin" : {
            "<80" : 47,
            "<100" : 47,
            "<150" : 52,
            "<200" : 61,
            "<400" : 81
          }
        },
        "30000" : {
          "puissanceDin" : {
            "<80" : 60,
            "<100" : 62,
            "<150" : 70,
            "<200" : 78,
            "<400" : 114
          }
        },
        "50000" : {
          "puissanceDin" : {
            "<80" : 97,
            "<100" : 100,
            "<150" : 106,
            "<200" : 113,
            "<400" : 166
          }
        },
        "70000" : {
          "puissanceDin" : {
            "<80" : 133,
            "<100" : 139,
            "<150" : 148,
            "<200" : 159,
            "<400" : 235
          }
        }
      }
    },
    "VO": {
      "30000": {
        "roulage" : {
          "10000" : {
            "puissanceDin" : {
              "<80" : 52,
              "<100" : 52,
              "<150" : 51,
              "<200" : 61,
              "<400" : 83
            }
          },
          "20000" : {
            "puissanceDin" : {
              "<80" : 60,
              "<100" : 59,
              "<150" : 62,
              "<200" : 77,
              "<400" : 106
            }
          },
          "30000" : {
            "puissanceDin" : {
              "<80" : 76,
              "<100" : 79,
              "<150" : 90,
              "<200" : 100,
              "<400" : 158
            }
          },
          "50000" : {
            "puissanceDin" : {
              "<80" : 124,
              "<100" : 132,
              "<150" : 147,
              "<200" : 150,
              "<400" : 236
            }
          },
          "70000" : {
            "puissanceDin" : {
              "<80" : 175,
              "<100" : 186,
              "<150" : 208,
              "<200" : 213,
              "<400" : 337
            }
          }
        }
      },
      "60000": {
        "roulage" : {
          "10000" : {
            "puissanceDin" : {
              "<80" : 60,
              "<100" : 60,
              "<150" : 55,
              "<200" : 69,
              "<400" : 94
            }
          },
          "20000" : {
            "puissanceDin" : {
              "<80" : 69,
              "<100" : 67,
              "<150" : 76,
              "<200" : 87,
              "<400" : 122
            }
          },
          "30000" : {
            "puissanceDin" : {
              "<80" : 89,
              "<100" : 94,
              "<150" : 113,
              "<200" : 120,
              "<400" : 189
            }
          },
          "50000" : {
            "puissanceDin" : {
              "<80" : 150,
              "<100" : 160,
              "<150" : 178,
              "<200" : 182,
              "<400" : 291
            }
          },
          "70000" : {
            "puissanceDin" : {
              "<80" : 210,
              "<100" : 227,
              "<150" : 254,
              "<200" : 262,
              "<400" : 421
            }
          }
        }
      },
      "90000": {
        "roulage" : {
          "10000" : {
            "puissanceDin" : {
              "<80" : 69,
              "<100" : 68,
              "<150" : 63,
              "<200" : 79,
              "<400" : 108
            }
          },
          "20000" : {
            "puissanceDin" : {
              "<80" : 79,
              "<100" : 77,
              "<150" : 85,
              "<200" : 100,
              "<400" : 140
            }
          },
          "30000" : {
            "puissanceDin" : {
              "<80" : 105,
              "<100" : 109,
              "<150" : 133,
              "<200" : 141,
              "<400" : 229
            }
          },
          "50000" : {
            "puissanceDin" : {
              "<80" : 183,
              "<100" : 194,
              "<150" : 217,
              "<200" : 223,
              "<400" : 362
            }
          },
          "70000" : {
            "puissanceDin" : {
              "<80" : 258,
              "<100" : 276,
              "<150" : 311,
              "<200" : 327,
              "<400" : 529
            }
          }
        }
      }
    }
  },
  "essence" : {
    "VN": {
      "roulage": {
        "10000" : {
          "puissanceDin" : {
            "<80" : 31,
            "<100" : 33,
            "<150" : 33,
            "<200" : 37,
            "<400" : 49
          }
        },
        "20000" : {
          "puissanceDin" : {
            "<80" : 36,
            "<100" : 39,
            "<150" : 43,
            "<200" : 48,
            "<400" : 64
          }
        },
        "30000" : {
          "puissanceDin" : {
            "<80" : 44,
            "<100" : 51,
            "<150" : 52,
            "<200" : 59,
            "<400" : 90
          }
        },
        "50000" : {
          "puissanceDin" : {
            "<80" : 68,
            "<100" : 78,
            "<150" : 82,
            "<200" : 87,
            "<400" : 135
          }
        },
        "70000" : {
          "puissanceDin": {
            "<80": 93,
            "<100": 108,
            "<150": 113,
            "<200": 122,
            "<400": 189
          }
        }
      }
    },
    "VO": {
      "30000": {
        "roulage" : {
          "10000" : {
            "puissanceDin" : {
              "<80" : 38,
              "<100" : 43,
              "<150" : 41,
              "<200" : 45,
              "<400" : 61
            }
          },
          "20000" : {
            "puissanceDin" : {
              "<80" : 44,
              "<100" : 51,
              "<150" : 54,
              "<200" : 60,
              "<400" : 85
            }
          },
          "30000" : {
            "puissanceDin" : {
              "<80" : 56,
              "<100" : 68,
              "<150" : 68,
              "<200" : 77,
              "<400" : 123
            }
          },
          "50000" : {
            "puissanceDin" : {
              "<80" : 89,
              "<100" : 107,
              "<150" : 112,
              "<200" : 117,
              "<400" : 194
            }
          },
          "70000" : {
            "puissanceDin" : {
              "<80" : 123,
              "<100" : 150,
              "<150" : 156,
              "<200" : 168,
              "<400" : 276
            }
          }
        }
      },
      "60000": {
        "roulage" : {
          "10000" : {
            "puissanceDin" : {
              "<80" : 44,
              "<100" : 47,
              "<150" : 45,
              "<200" : 46,
              "<400" : 63
            }
          },
          "20000" : {
            "puissanceDin" : {
              "<80" : 49,
              "<100" : 56,
              "<150" : 60,
              "<200" : 64,
              "<400" : 90
            }
          },
          "30000" : {
            "puissanceDin" : {
              "<80" : 67,
              "<100" : 78,
              "<150" : 78,
              "<200" : 87,
              "<400" : 143
            }
          },
          "50000" : {
            "puissanceDin" : {
              "<80" : 108,
              "<100" : 131,
              "<150" : 133,
              "<200" : 143,
              "<400" : 236
            }
          },
          "70000" : {
            "puissanceDin" : {
              "<80" : 152,
              "<100" : 184,
              "<150" : 192,
              "<200" : 206,
              "<400" : 344
            }
          }
        }
      },
      "90000": {
        "roulage" : {
          "10000" : {
            "puissanceDin" : {
              "<80" : 52,
              "<100" : 55,
              "<150" : 51,
              "<200" : 52,
              "<400" : 70
            }
          },
          "20000" : {
            "puissanceDin" : {
              "<80" : 56,
              "<100" : 64,
              "<150" : 69,
              "<200" : 74,
              "<400" : 104
            }
          },
          "30000" : {
            "puissanceDin" : {
              "<80" : 79,
              "<100" : 94,
              "<150" : 93,
              "<200" : 104,
              "<400" : 173
            }
          },
          "50000" : {
            "puissanceDin" : {
              "<80" : 133,
              "<100" : 161,
              "<150" : 166,
              "<200" : 177,
              "<400" : 297
            }
          },
          "70000" : {
            "puissanceDin" : {
              "<80" : 189,
              "<100" : 230,
              "<150" : 238,
              "<200" : 262,
              "<400" : 437
            }
          }
        }
      }
    }
  },
  "electrique" : {
    "VN": {
      "roulage": {
        "10000" : {
          "puissanceDin" : {
            "<80" : 22,
            "<100" : 23,
            "<150" : 23,
            "<200" : 26,
            "<400" : 35
          }
        },
        "20000" : {
          "puissanceDin" : {
            "<80" : 24,
            "<100" : 27,
            "<150" : 29,
            "<200" : 33,
            "<400" : 45
          }
        },
        "30000" : {
          "puissanceDin" : {
            "<80" : 30,
            "<100" : 35,
            "<150" : 35,
            "<200" : 40,
            "<400" : 62
          }
        },
        "50000" : {
          "puissanceDin" : {
            "<80" : 45,
            "<100" : 53,
            "<150" : 54,
            "<200" : 58,
            "<400" : 93
          }
        },
        "70000" : {
          "puissanceDin" : {
            "<80" : 62,
            "<100" : 72,
            "<150" : 75,
            "<200" : 81,
            "<400" : 130
          }
        }
      }
    },
    "VO": {
      "30000": {
        "roulage" : {
          "10000" : {
            "puissanceDin" : {
              "<80" : 28,
              "<100" : 30,
              "<150" : 30,
              "<200" : 31,
              "<400" : 40
            }
          },
          "20000" : {
            "puissanceDin" : {
              "<80" : 30,
              "<100" : 35,
              "<150" : 37,
              "<200" : 40,
              "<400" : 55
            }
          },
          "30000" : {
            "puissanceDin" : {
              "<80" : 38,
              "<100" : 45,
              "<150" : 46,
              "<200" : 52,
              "<400" : 81
            }
          },
          "50000" : {
            "puissanceDin" : {
              "<80" : 60,
              "<100" : 71,
              "<150" : 74,
              "<200" : 78,
              "<400" : 127
            }
          },
          "70000" : {
            "puissanceDin" : {
              "<80" : 82,
              "<100" : 99,
              "<150" : 104,
              "<200" : 110,
              "<400" : 179
            }
          }
        }
      },
      "60000": {
        "roulage" : {
          "10000" : {
            "puissanceDin" : {
              "<80" : 30,
              "<100" : 33,
              "<150" : 33,
              "<200" : 33,
              "<400" : 43
            }
          },
          "20000" : {
            "puissanceDin" : {
              "<80" : 33,
              "<100" : 38,
              "<150" : 40,
              "<200" : 44,
              "<400" : 59
            }
          },
          "30000" : {
            "puissanceDin" : {
              "<80" : 45,
              "<100" : 53,
              "<150" : 53,
              "<200" : 59,
              "<400" : 92
            }
          },
          "50000" : {
            "puissanceDin" : {
              "<80" : 72,
              "<100" : 86,
              "<150" : 89,
              "<200" : 94,
              "<400" : 153
            }
          },
          "70000" : {
            "puissanceDin" : {
              "<80" : 101,
              "<100" : 122,
              "<150" : 127,
              "<200" : 137,
              "<400" : 222
            }
          }
        }
      },
      "90000": {
        "roulage" : {
          "10000" : {
            "puissanceDin" : {
              "<80" : 35,
              "<100" : 38,
              "<150" : 38,
              "<200" : 38,
              "<400" : 48
            }
          },
          "20000" : {
            "puissanceDin" : {
              "<80" : 39,
              "<100" : 44,
              "<150" : 46,
              "<200" : 51,
              "<400" : 67
            }
          },
          "30000" : {
            "puissanceDin" : {
              "<80" : 53,
              "<100" : 63,
              "<150" : 63,
              "<200" : 69,
              "<400" : 110
            }
          },
          "50000" : {
            "puissanceDin" : {
              "<80" : 89,
              "<100" : 107,
              "<150" : 109,
              "<200" : 117,
              "<400" : 191
            }
          },
          "70000" : {
            "puissanceDin" : {
              "<80" : 125,
              "<100" : 152,
              "<150" : 156,
              "<200" : 173,
              "<400" : 282
            }
          }
        }
      }
    }
  }
};

const VALEUR_OPTION_S4 =  {
  "diesel" : {
    "VN": {
      "roulage": {
        "10000" : {
          "puissanceDin" : {
            "<80" : 53,
            "<100" : 54,
            "<150" : 55,
            "<200" : 70,
            "<400" : 89
          }
        },
        "20000" : {
          "puissanceDin" : {
            "<80" : 60,
            "<100" : 60,
            "<150" : 68,
            "<200" : 83,
            "<400" : 105
          }
        },
        "30000" : {
          "puissanceDin" : {
            "<80" : 74,
            "<100" : 77,
            "<150" : 89,
            "<200" : 100,
            "<400" : 140
          }
        },
        "50000" : {
          "puissanceDin" : {
            "<80" : 114,
            "<100" : 118,
            "<150" : 128,
            "<200" : 138,
            "<400" : 194
          }
        }
      }
    },
    "VO": {
      "30000": {
        "roulage" : {
          "10000" : {
            "puissanceDin" : {
              "<80" : 61,
              "<100" : 63,
              "<150" : 67,
              "<200" : 85,
              "<400" : 115
            }
          },
          "20000" : {
            "puissanceDin" : {
              "<80" : 68,
              "<100" : 70,
              "<150" : 78,
              "<200" : 99,
              "<400" : 136
            }
          },
          "30000" : {
            "puissanceDin" : {
              "<80" : 84,
              "<100" : 90,
              "<150" : 105,
              "<200" : 123,
              "<400" : 182
            }
          },
          "50000" : {
            "puissanceDin" : {
              "<80" : 130,
              "<100" : 140,
              "<150" : 161,
              "<200" : 169,
              "<400" : 255
            }
          }
        }
      },
      "60000": {
        "roulage" : {
          "10000" : {
            "puissanceDin" : {
              "<80" : 72,
              "<100" : 76,
              "<150" : 81,
              "<200" : 105,
              "<400" : 144
            }
          },
          "20000" : {
            "puissanceDin" : {
              "<80" : 81,
              "<100" : 83,
              "<150" : 100,
              "<200" : 123,
              "<400" : 169
            }
          },
          "30000" : {
            "puissanceDin" : {
              "<80" : 100,
              "<100" : 109,
              "<150" : 136,
              "<200" : 152,
              "<400" : 230
            }
          },
          "50000" : {
            "puissanceDin" : {
              "<80" : 160,
              "<100" : 173,
              "<150" : 199,
              "<200" : 213,
              "<400" : 324
            }
          }
        }
      },
      "90000": {
        "roulage" : {
          "10000" : {
            "puissanceDin" : {
              "<80" : 87,
              "<100" : 91,
              "<150" : 99,
              "<200" : 131,
              "<400" : 184
            }
          },
          "20000" : {
            "puissanceDin" : {
              "<80" : 98,
              "<100" : 99,
              "<150" : 121,
              "<200" : 152,
              "<400" : 213
            }
          },
          "30000" : {
            "puissanceDin" : {
              "<80" : 121,
              "<100" : 131,
              "<150" : 166,
              "<200" : 191,
              "<400" : 292
            }
          },
          "50000" : {
            "puissanceDin" : {
              "<80" : 194,
              "<100" : 213,
              "<150" : 245,
              "<200" : 268,
              "<400" : 415
            }
          }
        }
      }
    }
  },
  "essence" : {
    "VN": {
      "roulage": {
        "10000" : {
          "puissanceDin" : {
            "<80" : 41,
            "<100" : 45,
            "<150" : 46,
            "<200" : 52,
            "<400" : 67
          }
        },
        "20000" : {
          "puissanceDin" : {
            "<80" : 46,
            "<100" : 51,
            "<150" : 58,
            "<200" : 64,
            "<400" : 84
          }
        },
        "30000" : {
          "puissanceDin" : {
            "<80" : 55,
            "<100" : 63,
            "<150" : 67,
            "<200" : 76,
            "<400" : 109
          }
        },
        "50000" : {
          "puissanceDin" : {
            "<80" : 81,
            "<100" : 93,
            "<150" : 99,
            "<200" : 108,
            "<400" : 156
          }
        }
      }
    },
    "VO": {
      "30000": {
        "roulage" : {
          "10000" : {
            "puissanceDin" : {
              "<80" : 47,
              "<100" : 53,
              "<150" : 55,
              "<200" : 64,
              "<400" : 87
            }
          },
          "20000" : {
            "puissanceDin" : {
              "<80" : 52,
              "<100" : 60,
              "<150" : 68,
              "<200" : 78,
              "<400" : 109
            }
          },
          "30000" : {
            "puissanceDin" : {
              "<80" : 64,
              "<100" : 76,
              "<150" : 81,
              "<200" : 95,
              "<400" : 144
            }
          },
          "50000" : {
            "puissanceDin" : {
              "<80" : 95,
              "<100" : 114,
              "<150" : 122,
              "<200" : 135,
              "<400" : 209
            }
          }
        }
      },
      "60000": {
        "roulage" : {
          "10000" : {
            "puissanceDin" : {
              "<80" : 58,
              "<100" : 64,
              "<150" : 70,
              "<200" : 83,
              "<400" : 114
            }
          },
          "20000" : {
            "puissanceDin" : {
              "<80" : 63,
              "<100" : 72,
              "<150" : 85,
              "<200" : 100,
              "<400" : 139
            }
          },
          "30000" : {
            "puissanceDin" : {
              "<80" : 78,
              "<100" : 93,
              "<150" : 101,
              "<200" : 121,
              "<400" : 186
            }
          },
          "50000" : {
            "puissanceDin" : {
              "<80" : 118,
              "<100" : 143,
              "<150" : 155,
              "<200" : 174,
              "<400" : 273
            }
          }
        }
      },
      "90000": {
        "roulage" : {
          "10000" : {
            "puissanceDin" : {
              "<80" : 70,
              "<100" : 78,
              "<150" : 86,
              "<200" : 104,
              "<400" : 144
            }
          },
          "20000" : {
            "puissanceDin" : {
              "<80" : 76,
              "<100" : 89,
              "<150" : 104,
              "<200" : 125,
              "<400" : 176
            }
          },
          "30000" : {
            "puissanceDin" : {
              "<80" : 97,
              "<100" : 116,
              "<150" : 127,
              "<200" : 153,
              "<400" : 238
            }
          },
          "50000" : {
            "puissanceDin" : {
              "<80" : 150,
              "<100" : 181,
              "<150" : 197,
              "<200" : 224,
              "<400" : 353
            }
          }
        }
      }
    }
  },
  "electrique" : {
    "VN": {
      "roulage": {
        "10000" : {
          "puissanceDin" : {
              "<80" : 36,
              "<100" : 37,
              "<150" : 37,
              "<200" : 40,
              "<400" : 51
          }
        },
        "20000" : {
          "puissanceDin" : {
            "<80" : 38,
            "<100" : 41,
            "<150" : 45,
            "<200" : 49,
            "<400" : 64
          }
        },
        "30000" : {
          "puissanceDin" : {
            "<80" : 46,
            "<100" : 52,
            "<150" : 52,
            "<200" : 59,
            "<400" : 86
          }
        },
        "50000" : {
          "puissanceDin" : {
            "<80" : 66,
            "<100" : 75,
            "<150" : 76,
            "<200" : 81,
            "<400" : 125
          }
        }
      }
    },
    "VO": {
      "30000": {
        "roulage" : {
          "10000" : {
            "puissanceDin" : {
              "<80" : 39,
              "<100" : 45,
              "<150" : 49,
              "<200" : 53,
              "<400" : 68
            }
          },
          "20000" : {
            "puissanceDin" : {
              "<80" : 43,
              "<100" : 49,
              "<150" : 59,
              "<200" : 64,
              "<400" : 85
            }
          },
          "30000" : {
            "puissanceDin" : {
              "<80" : 52,
              "<100" : 61,
              "<150" : 68,
              "<200" : 76,
              "<400" : 113
            }
          },
          "50000" : {
            "puissanceDin" : {
              "<80" : 76,
              "<100" : 90,
              "<150" : 99,
              "<200" : 106,
              "<400" : 163
            }
          }
        }
      },
      "60000": {
        "roulage" : {
          "10000" : {
            "puissanceDin" : {
              "<80" : 43,
              "<100" : 47,
              "<150" : 53,
              "<200" : 55,
              "<400" : 70
            }
          },
          "20000" : {
            "puissanceDin" : {
              "<80" : 47,
              "<100" : 53,
              "<150" : 62,
              "<200" : 68,
              "<400" : 89
            }
          },
          "30000" : {
            "puissanceDin" : {
              "<80" : 60,
              "<100" : 70,
              "<150" : 76,
              "<200" : 84,
              "<400" : 125
            }
          },
          "50000" : {
            "puissanceDin" : {
              "<80" : 90,
              "<100" : 106,
              "<150" : 115,
              "<200" : 124,
              "<400" : 192
            }
          }
        }
      },
      "90000": {
        "roulage" : {
          "10000" : {
            "puissanceDin" : {
              "<80" : 48,
              "<100" : 53,
              "<150" : 60,
              "<200" : 61,
              "<400" : 77
            }
          },
          "20000" : {
            "puissanceDin" : {
              "<80" : 53,
              "<100" : 60,
              "<150" : 68,
              "<200" : 75,
              "<400" : 98
            }
          },
          "30000" : {
            "puissanceDin" : {
              "<80" : 68,
              "<100" : 81,
              "<150" : 87,
              "<200" : 97,
              "<400" : 146
            }
          },
          "50000" : {
            "puissanceDin" : {
              "<80" : 108,
              "<100" : 129,
              "<150" : 138,
              "<200" : 150,
              "<400" : 235
            }
          }
        }
      }
    }
  }
};

class stFinancement {
  constructor(
    prixVehiculeTTC,
    kilometrageVehicule,
    dateImmatriculation,
    garantieConstructeur,
    segmentViaxel,
    avecTVA,
    genre,
    puissanceFiscale: null,
    energie: null,
    eligibiliteLoab: null,
    coeffsVR: null,
    prixConstructeurTTC: null,
    origine: null,
    agrementS3S4: null,
    agrement1erJour: null,
    puissanceDin: null,
    codeEnergie: null,
    elibibiliteDirectDrive : null,
    prixVehiculeTTCHorsFrais : null
  ) {
    this.prixVehiculeTTC = parseFloat(prixVehiculeTTC);
    this.prixConstructeurTTC = parseFloat(prixConstructeurTTC);
    this.kilometrageVehicule = parseInt(kilometrageVehicule);
    this.dateImmatriculation = dateImmatriculation;
    this.garantieConstructeur = garantieConstructeur;
    this.prixVehiculeTTCHorsFrais = prixVehiculeTTCHorsFrais;

    // Calcul du nombre de mois sous garantie constructeur restant
    if (typeof this.dateImmatriculation !== "string") {
      this.garantieConstructeurRestante = 0;
    } else {
      var dateParts = this.dateImmatriculation.split("/");
      var immatriculation = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
      immatriculation.setMonth(immatriculation.getMonth()+parseInt(this.garantieConstructeur));
      function monthDiff(d1, d2) {
        var months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth() + 1;
        months += d2.getMonth() + 1;

        if (d1.getDate() > d2.getDate() ) {
          months -= 1;
        }
        return months <= 0 ? 0 : months;
      }
      var tmpGarantie = monthDiff(new Date(), immatriculation);
      this.garantieConstructeurRestante = isNaN(tmpGarantie) ? 0 : tmpGarantie;
    }

    this.segmentViaxel = segmentViaxel ? segmentViaxel.toUpperCase() : '';
    this.etatVehiculeViaxel = null;
    this.optionsNonEligibles = null;
    this.avecTVA = avecTVA == 1 ? true : false;
    this.genre = genre ? genre.toUpperCase() : '';
    this.puissanceFiscale = puissanceFiscale;

    if (energie && energie.toLowerCase() == 'hybride') {
      if (codeEnergie !== null && (codeEnergie === 'EE' || codeEnergie === 'EH')) {
        energie = 'essence';
      } else {
        energie = 'diesel';
      }
    }
    this.energie = (energie && energie.toLowerCase().match(/(essence|diesel|electrique)/g) !== null) ? energie.toLowerCase().match(/(essence|diesel|electrique)/g)[0] : null;

    this.energieCalculOption = (energie && energie.toLowerCase() == "electrique" ?  "electrique" : this.energie);

    this.agrementS3S4 = agrementS3S4 != null ? agrementS3S4 : 1;
    this.agrement1erJour = agrement1erJour != null ? agrement1erJour : 1;
    this.eligibiliteLoab = eligibiliteLoab ? eligibiliteLoab : null;
    this.coeffsVR = coeffsVR ? coeffsVR : null;
    this.origine = origine ? origine : ORIGIN_DEFAULT;
    this.puissanceDin = puissanceDin ? puissanceDin : null;

    this.elibibiliteDirectDrive = elibibiliteDirectDrive != null ? elibibiliteDirectDrive : 0;

    this.vxlvat = {
      "eligibiliteVehicule": "ageVehicule < eightYears && ('genre' == 'VP' || 'genre' == 'VU' || 'genre' == 'CTTE' || 'genre' == 'DERIVE VP')",
      "eligibiliteMensualiteBasse": "mensualite >= 12",
      "eligibiliteMensualiteHaute": "mensualite <= 72",
      "eligibiliteApport" : "apport <= (prixVehiculeTTC - 5000)",
      "tauxDeBase" : jsonTauxFinancement['TAUXVXLVAT'] / 100,
      "taeg" : jsonTauxFinancement['TAEGVXLVAT'],
      "tauxDebiteurFixe" :jsonTauxFinancement['TDFVXLVAT'],
      "options": {
        "VXLVVI": {
          "eligibiliteVehicule": "ageVehicule < eightYears",
          "eligibiliteMensualiteBasse": "mensualite >= 12",
          "eligibiliteMensualiteHaute": "mensualite <= 72",
          "eligibiliteApport" : "apport <= (prixVehiculeTTC - 5000)",
          "valeurs": {
            "coef": 0.00140,
            "forfait": 5
          }
        },
        "VXLVCA": {
          "eligibiliteVehicule": "ageVehicule < eightYears",
          "eligibiliteMensualiteBasse": "mensualite >= 12",
          "eligibiliteMensualiteHaute": "mensualite <= 72",
          "eligibiliteApport" : "apport <= (prixVehiculeTTC - 5000)",
          "valeurs": {
            "coef": 0.00150,
            "forfait": 5
          }
        },
        "VXLVCR": {
          "eligibiliteVehicule": "ageVehicule < sevenYears && kilometrageVehicule < 150000 && ('etatVehiculeViaxel' == 'VN' || ('etatVehiculeViaxel' == 'VO' && puissanceDin <= 400))",
          "eligibiliteMensualiteBasse": "mensualite >= 12",
          "eligibiliteMensualiteHaute": "mensualite <= 72",
          "eligibiliteApport" : "apport <= (prixVehiculeTTC - 5000)",
          "valeurs": VALEUR_OPTION_SECURICAR
        },
        "VXLVS4": {
          "eligibiliteVehicule": "ageVehicule < fiveYears && kilometrageVehicule < 90000 && ('genre' == 'VP' || 'genre' == 'CTTE' || 'genre' == 'DERIVE VP') && agrementS3S4 == 1",
          "eligibiliteReglettes": "",
          "valeurs": VALEUR_OPTION_S4
        }
      }
    };
    this.vxlloa = {
      "eligibiliteVehicule": "ageVehicule < fiveYears && avecTVA == true && ('genre' == 'VP' || 'genre' == 'VU' || 'genre' == 'CTTE' || 'genre' == 'DERIVE VP')",
      "eligibiliteMensualiteBasse": "mensualite >= 25",
      "eligibiliteMensualiteHaute": "mensualite <= 60",
      "eligibiliteApport" : "apport <= (prixVehiculeTTC * 0.35)",
      "taux": jsonTauxFinancement['TAUXVXLLOA'] / 100,
      "options": {
        "VXLLVI": {
          "eligibiliteVehicule": "ageVehicule < fiveYears",
          "eligibiliteMensualiteBasse": "mensualite >= 25",
          "eligibiliteMensualiteHaute": "mensualite <= 60",
          "eligibiliteApport" : "apport <= (prixVehiculeTTC * 0.35)",
          "valeurs": {
            "coef": 0.00110,
            "forfait": 0
          }
        },
        "VXLLCA": {
          "eligibiliteVehicule": "ageVehicule < fiveYears",
          "eligibiliteReglettes": "",
          "valeurs": {
            "coef": 0.00140,
            "forfait": 0
          }
        },
        "VXLLCR": {
          "eligibiliteVehicule": "ageVehicule < fiveYears && kilometrageVehicule < 150000 && ('etatVehiculeViaxel' == 'VN' || ('etatVehiculeViaxel' == 'VO' && puissanceDin <= 400))",
          "eligibiliteReglettes": "",
          "valeurs": VALEUR_OPTION_SECURICAR
        },
        "VXLLS4": {
          "eligibiliteVehicule": "ageVehicule < fiveYears && kilometrageVehicule < 90000 && ('genre' == 'VP' || 'genre' == 'CTTE' || 'genre' == 'DERIVE VP') && agrementS3S4 == 1",
          "eligibiliteReglettes": "",
          "valeurs": VALEUR_OPTION_S4
        }
      }
    };
    this.vxlloab = {
      "eligibiliteVehicule": "ageVehicule < fiveYears && avecTVA == true && ((('genre' == 'VP' || 'genre' == 'VU' || 'genre' == 'CTTE' || 'genre' == 'DERIVE VP') && repriseMarchand == 0) || repriseMarchand == 1)",
      "eligibiliteMensualiteBasse": "mensualite >= 25",
      "eligibiliteMensualiteHaute": "(mensualite <= 48 && repriseMarchand == 0) || (mensualite <= 60 && repriseMarchand == 1)",
      "eligibiliteApport": "apport <= (prixVehiculeTTC * 0.30)",
      "eligibiliteKilometrage": "(kilometrageTotal <= 80000) || repriseMarchand == 1",
      "tauxBase": jsonTauxFinancement['TAUXVXLLOAB'] / 100,
      "taux": {
        "<=15000": 0.02,
        "<=20000": {
          "energie": {
            "essence": {
              "puissanceFiscale": {
                "3": 0.058,
                "4": 0.067,
                "5": 0.072,
                "6": 0.086,
                "7": 0.094,
                "8": 0.116,
                "9": 0.132,
                "10": 0.153,
                "11": 0.175,
              }
            },
            "diesel": {
              "puissanceFiscale": {
                "3": 0.065,
                "4": 0.074,
                "5": 0.095,
                "6": 0.11,
                "7": 0.121,
                "8": 0.137,
                "9": 0.142,
                "10": 0.164,
                "11": 0.183,
              }
            }
          }
        },
        ">20000": {
          "energie": {
            "essence": {
              "puissanceFiscale": {
                "3": 0.106,
                "4": 0.129,
                "5": 0.139,
                "6": 0.166,
                "7": 0.191,
                "8": 0.226,
                "9": 0.257,
                "10": 0.293,
                "11": 0.345,
              }
            },
            "diesel": {
              "puissanceFiscale": {
                "3": 0.129,
                "4": 0.152,
                "5": 0.19,
                "6": 0.223,
                "7": 0.24,
                "8": 0.268,
                "9": 0.288,
                "10": 0.323,
                "11": 0.36,
              }
            }
          }
        }
      },
      "tauxDirectDrive": {
        "<=15000": 0.02,
        ">15000": {
          "energie": {
            "essence": {
              "puissanceFiscale": {
                "3": 0.058,
                "4": 0.067,
                "5": 0.072,
                "6": 0.086,
                "7": 0.094,
                "8": 0.116,
                "9": 0.132,
                "10": 0.153,
                "11": 0.175,
              }
            },
            "electrique": {
              "puissanceFiscale": {
                "3": 0.058,
                "4": 0.067,
                "5": 0.072,
                "6": 0.086,
                "7": 0.094,
                "8": 0.116,
                "9": 0.132,
                "10": 0.153,
                "11": 0.175,
              }
            },
            "diesel": {
              "puissanceFiscale": {
                "3": 0.065,
                "4": 0.074,
                "5": 0.095,
                "6": 0.11,
                "7": 0.121,
                "8": 0.137,
                "9": 0.142,
                "10": 0.164,
                "11": 0.183,
              }
            }
          }
        }
      },
    };

    this.provat = {
      "eligibiliteVehicule": "ageVehicule < fiveYears && ('genre' == 'VP' || 'genre' == 'VU' || 'genre' == 'CTTE' || 'genre' == 'DERIVE VP')",
      "eligibiliteMensualiteBasse": "mensualite >= 13",
      "eligibiliteMensualiteHaute": "mensualite <= 72",
      "eligibiliteApport": "apport <= (prixVehiculeTTC - 5000)",
      "tauxDeBase" :  jsonTauxFinancement['TAUXPROVAT'] / 100,
      "taeg" :  jsonTauxFinancement['TAEGPROVAT'],
      "tauxDebiteurFixe" :  jsonTauxFinancement['TDFPROVAT'] ,
      "options": {
        "PROVVI": {
          "eligibiliteVehicule": "ageVehicule < eightYears",
          "eligibiliteMensualiteBasse": "mensualite >= 13",
          "eligibiliteMensualiteHaute": "mensualite <= 60",
          "eligibiliteApport": "apport <= (prixVehiculeTTC - 5000)",
          "valeurs": {
            "coef": 0.00090,
            "forfait": 0
          }
        },
        "PROVCA": {
          "eligibiliteVehicule": "ageVehicule < eightYears",
          "eligibiliteMensualiteBasse": "mensualite >= 13",
          "eligibiliteMensualiteHaute": "mensualite <= 60",
          "eligibiliteApport": "apport <= (prixVehiculeTTC - 5000)",
          "valeurs": {
            "coef": 0.00150,
            "forfait": 0
          }
        },
        "PROVCR": {
          "eligibiliteVehicule": "ageVehicule < sevenYears && kilometrageVehicule < 150000 && ('etatVehiculeViaxel' == 'VN' || ('etatVehiculeViaxel' == 'VO' && puissanceDin <= 400))",
          "eligibiliteMensualiteBasse": "mensualite >= 13",
          "eligibiliteMensualiteHaute": "mensualite <= 60",
          "eligibiliteApport": "apport <= (prixVehiculeTTC - 5000)",
          "valeurs": VALEUR_OPTION_SECURICAR
        },
        "PROVS3": {
          "eligibiliteVehicule": "ageVehicule < fiveYears && kilometrageVehicule < 90000 && puissanceDin <= 400 && agrementS3S4 == 1",
          "eligibiliteReglettes": "",
          "valeurs": VALEUR_OPTION_S3
        }
      }
    };

    this.proloa = {
      "eligibiliteVehicule": "ageVehicule < fiveYears && avecTVA == true && ('genre' == 'VP' || 'genre' == 'VU' || 'genre' == 'CTTE' || 'genre' == 'DERIVE VP')",
      "eligibiliteMensualiteBasse": "mensualite >= 36",
      "eligibiliteMensualiteHaute": "mensualite <= 60",
      "eligibiliteApport": "apport <= (prixVehiculeTTC * 0.25)",
      "taux": jsonTauxFinancement['TAUXPROLOA'] / 100,
      "options": {
        "PROLVI": {
          "eligibiliteVehicule": "ageVehicule < eightYears",
          "eligibiliteMensualiteBasse": "mensualite >= 13",
          "eligibiliteMensualiteHaute": "mensualite <= 60",
          "eligibiliteApport": "apport <= (prixVehiculeTTC * 0.25)",
          "valeurs": {
            "coef": 0.00090,
            "forfait": 0
          }
        },
        "PROLCA": {
          "eligibiliteVehicule": "ageVehicule < eightYears",
          "eligibiliteMensualiteBasse": "mensualite >= 13",
          "eligibiliteMensualiteHaute": "mensualite <= 60",
          "eligibiliteApport": "apport <= (prixVehiculeTTC * 0.25)",
          "valeurs": {
            "coef": 0.00140,
            "forfait": 0
          }
        },
        "PROLCR": {
          "eligibiliteVehicule": "ageVehicule < fiveYears && kilometrageVehicule < 150000 && ('etatVehiculeViaxel' == 'VN' || ('etatVehiculeViaxel' == 'VO' && puissanceDin <= 400))",
          "eligibiliteMensualiteBasse": "mensualite >= 13",
          "eligibiliteMensualiteHaute": "mensualite <= 60",
          "eligibiliteApport": "apport <= (prixVehiculeTTC * 0.25)",
          "valeurs": VALEUR_OPTION_SECURICAR
        },
        "PROLS3": {
          "eligibiliteVehicule": "ageVehicule < fiveYears && kilometrageVehicule < 90000 && puissanceDin <= 400 && agrementS3S4 == 1",
          "eligibiliteReglettes": "",
          "valeurs": VALEUR_OPTION_S3
        }
      }
    };


    this.proloab = {
      "eligibiliteVehicule": "ageVehicule < fiveYears && avecTVA == true && ('genre' == 'VU' || 'genre' == 'VP' || 'genre' == 'CTTE' || 'genre' == 'DERIVE VP')",
      "eligibiliteMensualiteBasse": "mensualite >= 25",
      "eligibiliteMensualiteHaute": "mensualite <= 60",
      "eligibiliteApport": "apport <= (prixVehiculeTTC * 0.30)",
      "tauxBase": jsonTauxFinancement['TAUXPROLOAB'] / 100,
      "tauxDirectDrive": {
        "<=15000": 0.02,
        ">15000": {
          "energie": {
            "essence": {
              "puissanceFiscale": {
                "3": 0.058,
                "4": 0.067,
                "5": 0.072,
                "6": 0.086,
                "7": 0.094,
                "8": 0.116,
                "9": 0.132,
                "10": 0.153,
                "11": 0.175,
              }
            },
            "electrique": {
              "puissanceFiscale": {
                "3": 0.058,
                "4": 0.067,
                "5": 0.072,
                "6": 0.086,
                "7": 0.094,
                "8": 0.116,
                "9": 0.132,
                "10": 0.153,
                "11": 0.175,
              }
            },
            "diesel": {
              "puissanceFiscale": {
                "3": 0.065,
                "4": 0.074,
                "5": 0.095,
                "6": 0.11,
                "7": 0.121,
                "8": 0.137,
                "9": 0.142,
                "10": 0.164,
                "11": 0.183,
              }
            }
          }
        }
      },
    };


  }

  /*
                _
     _   _ ____| |_
    | | | / _  |  _)
     \ V ( ( | | |__
      \_/ \_||_|\___)
  */
  calculMensualiteVAT(apport, duree, kilometrage, optionsSouscrites) {
    this.apport = apport;
    this.duree = duree;
    this.kilometrage = kilometrage;

    var eligibiliteVehicule = this.vxlvat.eligibiliteVehicule;
    eligibiliteVehicule = eligibiliteVehicule
      .replace('ageVehicule', this.getAgeVehicule())
      .replace('eightYears', eightYears)
      .replace(/genre/g, this.genre);

    if (this.dateImmatriculation === '' || this.dateImmatriculation === null) {
      var errorDescription = [];
      errorDescription['date_immat'] = "non_renseignee";
      return {"error": true, "value": "r", "description": errorDescription};
    }

    if (this.getAgeVehicule() > eightYears) {
      var errorDescription = [];
      errorDescription['age'] = ">";
      return {"error": true, "value": "r", "description": errorDescription};
    }

    if (eval(eligibiliteVehicule) === false) {
      return {"error": true, "value": "v"};
    }

    var taux = this.vxlvat['tauxDeBase'];

    var mensualiteBase = this.calculValeurMensualiteVAT(this.prixVehiculeTTC - apport, taux, this.duree);
    var prixOptions = this.calculPrixOptions(optionsSouscrites, apport, 'VXLVAT');
    var taeg = this.vxlvat['taeg'];
    var tdf = this.vxlvat['tauxDebiteurFixe'];
    var forfaitVAT = this.vxlvat['options']['VXLVVI']['valeurs']['forfait'];

    return {
        "error": false,
        "value": mensualiteBase + prixOptions,
        "sansOpt": mensualiteBase,
        "securivie" : isNaN(this.calculVXLVVI() + forfaitVAT) ? null : parseFloat(this.calculVXLVVI() + forfaitVAT),
        "securicap" : isNaN(this.calculVXLVCA() + forfaitVAT) ? null : parseFloat(this.calculVXLVCA() + forfaitVAT),
        "securicar" : isNaN(this.calculVXLVCR() + forfaitVAT) ? null : parseFloat(this.calculVXLVCR() + forfaitVAT),
        "securi4" :  isNaN(this.calculVXLVS4() + forfaitVAT) ? null : parseFloat(this.calculVXLVS4() + forfaitVAT),
        "taeg": taeg,
        "tdf": tdf,
        "prixOptions": prixOptions
    };
  }

  calculValeurMensualiteVAT(paramMontantFinance, paramTaux, paramDuree) {
    var interet1Ermois	= paramMontantFinance * paramTaux / 12;
    var montantFinance	= paramMontantFinance + interet1Ermois;
    var duree = parseInt(paramDuree)+1;

    return (montantFinance * paramTaux) / (12 * (1 - Math.pow(1 + (paramTaux / 12),((-1 * duree)+1))));
  }

  /*
                _
     _   _ ____| |_
    | | | / _  |  _)
     \ V ( ( | | |__
      \_/ \_||_|\___) PRO
  */
  calculMensualitePROVAT(apport, duree, kilometrage, optionsSouscrites) {
    this.apport = apport;
    this.duree = duree;
    this.kilometrage = kilometrage;

    var eligibiliteVehicule = this.provat.eligibiliteVehicule;
    eligibiliteVehicule = eligibiliteVehicule
      .replace('ageVehicule', this.getAgeVehicule())
      .replace('eightYears', eightYears)
      .replace('avecTVA', this.avecTVA)
      .replace(/genre/g, this.genre);

    if (this.dateImmatriculation === '' || this.dateImmatriculation === null) {
      var errorDescription = [];
      errorDescription['date_immat'] = "non_renseignee";
      return {"error": true, "value": "r", "description": errorDescription};
    }

    if (this.getAgeVehicule() > eightYears) {
      var errorDescription = [];
      errorDescription['age'] = ">";
      return {"error": true, "value": "r", "description": errorDescription};
    }
    if (this.genre == 'VU' || this.genre == 'CTTE' || this.genre == 'DERIVE VP') {
      if (apport < (this.prixVehiculeTTC / 1.2 * 0.2)) {
        var errorDescription = [];
        errorDescription['apport'] = "<";
        return {"error": true, "value": "r", "description": errorDescription};
      }
      if (duree > 60) {
        var errorDescription = [];
        errorDescription['duree'] = ">";
        return {"error": true, "value": "r", "description": errorDescription};
      }
    }

    if (eval(eligibiliteVehicule) === false) {
      return {"error": true, "value": "v"};
    }

    var eligibiliteError = this.checkReglettesEligibilite('provat');

    if (eligibiliteError.error === true) {
      return { "error": true, "value": "r", "description": eligibiliteError };
    }

    var taux = this.provat['tauxDeBase'];

    var mensualiteBase = this.calculValeurMensualiteVAT(this.prixVehiculeTTC - apport, taux, this.duree);

    var prixOptions = this.calculPrixOptions(optionsSouscrites, apport, 'PROVAT');
    var taeg = this.provat['taeg'];
    var tdf = this.provat['tauxDebiteurFixe'];

    return {
        "error": false,
        "value": mensualiteBase + prixOptions,
        "sansOpt": mensualiteBase,
        "securivie" : isNaN(this.calculPROVVI()) ? null : parseFloat(this.calculPROVVI()),
        "securicap" : isNaN(this.calculPROVCA()) ? null : parseFloat(this.calculPROVCA()),
        "securicar" : isNaN(this.calculPROVCR()) ? null : parseFloat(this.calculPROVCR()),
        "securi3" : isNaN(this.calculPROVS3()) ? null : parseFloat(this.calculPROVS3()),
        "taeg": taeg,
        "tdf": tdf,
        "prixOptions": prixOptions
    };
  }


  /*
     _
    | |
    | | ___   ____
    | |/ _ \ / _  |
    | | |_| ( ( | |
    |_|\___/ \_||_|
  */
  calculMensualiteLOA(apport, duree, kilometrage, optionsSouscrites, vr = LOA_VR_BASE) {
    this.apport = apport;
    this.duree = duree;
    this.kilometrage = kilometrage;
    if (vr != LOA_VR_1 && vr != LOA_VR_10 && vr != LOA_VR_BASE) {
      vr = LOA_VR_BASE;
    }

    var eligibiliteVehicule = this.vxlloa.eligibiliteVehicule;
    eligibiliteVehicule = eligibiliteVehicule
      .replace('ageVehicule', this.getAgeVehicule())
      .replace('fiveYears', fiveYears)
      .replace('avecTVA', this.avecTVA)
      .replace(/genre/g, this.genre);

    if (this.dateImmatriculation === '' || this.dateImmatriculation === null) {
      var errorDescription = [];
      errorDescription['date_immat'] = "non_renseignee";
      return {"error": true, "value": "r", "description": errorDescription};
    }

    if (this.getAgeVehicule() > fiveYears) {
      var errorDescription = [];
      errorDescription['age'] = ">";
      return {"error": true, "value": "r", "description": errorDescription};
    }
    if (eval(eligibiliteVehicule) === false) {
      return {"error": true, "value": "v"};
    }

    var eligibiliteError = this.checkReglettesEligibilite('vxlloa');

    if (eligibiliteError.error === true) {
      return { "error": true, "value": "r", "description": eligibiliteError };
    }

    var taux = this.vxlloa.taux;
    var mensualiteBase = this.calculValeurMensualiteLOA(this.prixVehiculeTTC, this.apport, this.duree, taux, this.prixVehiculeTTC * vr)
    var prixOptions = parseFloat(this.calculPrixOptions(optionsSouscrites, apport, 'VXLLOA'));
    var premierLoyer = parseFloat(this.apport > 0 ? this.apport : mensualiteBase);

    return {
      "error": false,
      "value": mensualiteBase + prixOptions,
      "sansOpt": mensualiteBase,
      "securivie" : isNaN(this.calculVXLLVI()) ? null : parseFloat(this.calculVXLLVI()),
      "securicap" : isNaN(this.calculVXLLCA()) ? null : parseFloat(this.calculVXLLCA()),
      "securicar" : isNaN(this.calculVXLLCR()) ? null : parseFloat(this.calculVXLLCR()),
      "securi4" : isNaN(this.calculVXLLS4()) ? null : parseFloat(this.calculVXLLS4()),
      "premierLoyer": premierLoyer,
      "vr": this.prixVehiculeTTC * vr,
      "pourcentVr": vr * 100,
      "premierLoyerAvecOpt": premierLoyer + prixOptions,
      "prixOptions": prixOptions
    };
  }

  /*
     _
    | |
    | | ___   ____
    | |/ _ \ / _  |
    | | |_| ( ( | |
    |_|\___/ \_||_| PRO
  */
  calculMensualitePROLOA(
    apport,
    duree,
    kilometrage,
    optionsSouscrites,
    vr = LOA_VR_BASE,
    ht = this.genre == 'VU' || this.genre == 'CTTE' || this.genre == 'DERIVE VP'
  ) {
    this.apport = apport;
    this.duree = duree;
    this.kilometrage = kilometrage;

    var eligibiliteVehicule = this.proloa.eligibiliteVehicule;
    eligibiliteVehicule = eligibiliteVehicule
      .replace('ageVehicule', this.getAgeVehicule())
      .replace('fiveYears', fiveYears)
      .replace('avecTVA', this.avecTVA)
      .replace(/genre/g, this.genre);

    if (this.dateImmatriculation === '' || this.dateImmatriculation === null) {
      var errorDescription = [];
      errorDescription['date_immat'] = "non_renseignee";
      return {"error": true, "value": "r", "description": errorDescription};
    }

    if (this.getAgeVehicule() > fiveYears) {
      var errorDescription = [];
      errorDescription['age'] = ">";
      return {"error": true, "value": "r", "description": errorDescription};
    }
    if (eval(eligibiliteVehicule) === false) {
      return {"error": true, "value": "v"};
    }

    var eligibiliteError = this.checkReglettesEligibilite('proloa');

    if (eligibiliteError.error === true) {
      return { "error": true, "value": "r", "description": eligibiliteError };
    }

    var taux = this.proloa.taux;
    var mensualiteBase = this.calculValeurMensualiteLOA(this.prixVehiculeTTC, this.apport, this.duree, taux, this.prixVehiculeTTC * vr);
    var prixOptions = parseFloat(this.calculPrixOptions(optionsSouscrites, apport, 'PROLOA'));
    var premierLoyer = parseFloat(this.apport > 0 ? this.apport : mensualiteBase);

    return {
      "error": false,
      "value": ht ? (mensualiteBase / (1+TAUX_TVA)) + prixOptions  : mensualiteBase + prixOptions,
      "sansOpt": ht ? mensualiteBase / (1+TAUX_TVA) : mensualiteBase,
      "securivie" : isNaN(this.calculPROLVI())? null : parseFloat(this.calculPROLVI()),
      "securicap" : isNaN(this.calculPROLCA())? null : parseFloat(this.calculPROLCA()),
      "securicar" : isNaN(this.calculPROLCR())? null : parseFloat(this.calculPROLCR()),
      "securi3" : isNaN(this.calculPROLS3())? null : parseFloat(this.calculPROLS3()),
      "premierLoyer": ht ? premierLoyer / (1+TAUX_TVA) : premierLoyer,
      "vr": ht ? (this.prixVehiculeTTC * vr) / (1+TAUX_TVA) : this.prixVehiculeTTC * vr,
      "pourcentVr": vr * 100,
      "premierLoyerAvecOpt": ht ? (premierLoyer / (1+TAUX_TVA)) + prixOptions : premierLoyer + prixOptions,
      "ht" : ht,
      "prixOptions": prixOptions
    };
  }

  calculMensualitePROLOAB(
      apport, duree, kilometrage, optionsSouscrites, vrSaisie = null,    ht = this.genre == 'VU' || this.genre == 'CTTE' || this.genre == 'DERIVE VP'
  ) {
    this.apport = apport;
    this.duree = duree;
    this.kilometrage = kilometrage;
    this.kilometrageTotal = (this.duree / 12) * this.kilometrage;

    // Eligibilité véhicule
    var eligibiliteVehicule = this.proloab.eligibiliteVehicule;

    eligibiliteVehicule = eligibiliteVehicule
        .replace('ageVehicule', this.getAgeVehicule())
        .replace('fiveYears', fiveYears)
        .replace('avecTVA', this.avecTVA)
        .replace(/genre/g, this.genre);

    if (this.dateImmatriculation === '' || this.dateImmatriculation === null) {
      var errorDescription = [];
      errorDescription['date_immat'] = "non_renseignee";
      return {"error": true, "value": "r", "description": errorDescription};
    }


    if (this.getAgeVehicule() > fiveYears) {
      var errorDescription = [];
      errorDescription['age'] = ">";
      return {"error": true, "value": "r", "description": errorDescription};
    }

    if (eval(eligibiliteVehicule) === false
        || !(this.puissanceFiscale && this.energie && this.elibibiliteDirectDrive == 1)
    ) {
      return {"error": true, "value": "v"};
    }

    var eligibiliteError = this.checkReglettesEligibilite('proloab');

    if (eligibiliteError.error === true) {

      return { "error": true, "value": "r", "description": eligibiliteError };
    }

    var taux = this.proloab.tauxBase;

    var vrMin = 0;
    var vrMax = 0;


      var donneesVr = this.getVRLoaBallonDirectDrive();
      var vr = donneesVr['vr'];
      vrMin = Math.ceil(donneesVr['vrMin']);
      vrMax = Math.ceil(donneesVr['vrMax']);

      if (vrSaisie != null) {
        vrSaisie = parseFloat(vrSaisie);
        if (!isNaN(vrSaisie) && vrSaisie >= vrMin && vrSaisie <= vrMax) {
          vr = vrSaisie;
        }
      }


    if (vr <= 0)  {
      return {"error": true, "value": "r", "description": true};
    }

    var mensualiteBase = this.calculValeurMensualiteLOA(this.prixVehiculeTTC, this.apport, this.duree, taux, vr)
    var prixOptions = parseFloat(this.calculPrixOptions(optionsSouscrites, apport, 'PROLOAB'));
    var premierLoyer = parseFloat(this.apport > 0 ? this.apport : mensualiteBase);

    var coeffKm = 0;
    coeffKm = this.proloab.tauxDirectDrive['>15000'].energie[this.energie].puissanceFiscale[this.puissanceFiscale > 11 ? 11 : this.puissanceFiscale];

    return {
      "error": false,
      "value":  ht ? (mensualiteBase / (1+TAUX_TVA)) + prixOptions  : mensualiteBase + prixOptions,
      "sansOpt": ht ? mensualiteBase / (1+TAUX_TVA) : mensualiteBase,
      "securivie" : isNaN(this.calculPROLBVI())? null : parseFloat(this.calculPROLBVI()),
      "securicap" : isNaN(this.calculPROLBCA())? null : parseFloat(this.calculPROLBCA()),
      "securicar" : isNaN(this.calculPROLBCR())? null : parseFloat(this.calculPROLBCR()),
      "securi3" : isNaN(this.calculPROLBS3())? null : parseFloat(this.calculPROLBS3()),
      "premierLoyer":  ht ? premierLoyer / (1+TAUX_TVA) : premierLoyer,
      "vr": ht ? vr / (1+TAUX_TVA) : vr,
      "pourcentVr": (vr / this.prixVehiculeTTC) * 100,
      "coefKm": coeffKm,
      "premierLoyerAvecOpt": ht ? (premierLoyer / (1+TAUX_TVA)) + prixOptions : premierLoyer + prixOptions,
      "prixOptions": prixOptions,
      "vrMin" : vrMin,
      "vrMax" : vrMax,
      "ht" : ht,
    };
  }

  calculValeurMensualiteLOA(prixVehiculeTTC, apport, duree, taux, vr, depotGarantie) {
    if (typeof depotGarantie == 'undefined') {
      depotGarantie = LOA_GARANTIE_BASE;
    }

    if ((apport + depotGarantie) > 0) {
      var tmp = this.va((taux/12), 1, 0, -((vr/1.2) - depotGarantie), 0);
    } else {
      var tmp = vr/1.2;
    }

    if ((apport + depotGarantie) > 0) {
      return this.vpm(taux/12, (duree-1), -((prixVehiculeTTC/1.2) - (apport/1.2) - depotGarantie), tmp, 0) * 1.2;
    } else {
      return this.vpm(taux/12, duree, -((prixVehiculeTTC/1.2) - (apport/1.2)), tmp, 1) * 1.2;
    }
  }

  /**
   * Cette fonction calcule la valeur actuelle d’un investissement.
   * La valeur actuelle correspond à la somme que représente aujourd’hui un ensemble de remboursements futurs.
   * Par exemple, lorsque vous faites un emprunt, le montant de l’emprunt représente la valeur actuelle pour le prêteur.
   */
  va(taux, npm, vpm, vc = 0, type = 0)
  {
    if (
      isNaN(parseFloat(taux))
      || isNaN(parseFloat(npm))
      || isNaN(parseFloat(vpm))
      || isNaN(parseFloat(vc))
      || isNaN(parseFloat(type))
    ) {
        return false;
    }

    if (type > 1|| type < 0) {
        return false;
    }

    var tauxAct = Math.pow(1 + taux, -npm);

    if ((1 - tauxAct) == 0) {
        return 0;
    }

    var va = vpm * (1 + taux * type) * (1 - tauxAct) / taux - vc * tauxAct;

    return va;
  }

  /**
   * Cette fonction calcule le remboursement d’un emprunt sur la base de remboursements et d’un taux d’intérêt constants.
   */
  vpm(taux, npm, va, vc = 0, type = 0)
  {
      if (
        isNaN(parseFloat(taux))
        || isNaN(parseFloat(npm))
        || isNaN(parseFloat(va))
        || isNaN(parseFloat(vc))
        || isNaN(parseFloat(type))
      ) {
          return false;
      }

      if (type > 1 || type < 0) {
          return false;
      }

      var tauxAct = Math.pow(1 + taux, -npm);

      if ((1 - tauxAct) == 0) {
          return 0;
      }

      var vpm = ((va + vc * tauxAct) * taux / (1 - tauxAct) ) / (1 + taux * type);

      return -vpm;
  }

  /* _                _           _ _
    | |              | |         | | |
    | | ___   __ _   | |__   __ _| | | ___  _ __
    | |/ _ \ / _` |  | '_ \ / _` | | |/ _ \| '_ \
    | | (_) | (_| |  | |_) | (_| | | | (_) | | | |
    |_|\___/ \__,_|  |_.__/ \__,_|_|_|\___/|_| |_|
  */
  calculMensualiteLOAB(apport, duree, kilometrage, optionsSouscrites, repriseMarchand = 0, vrSaisie = null)
  {

    this.apport = apport;
    this.duree = duree;
    this.kilometrage = kilometrage;
    this.repriseMarchand = repriseMarchand;
    this.kilometrageTotal = (this.duree / 12) * this.kilometrage;

    // Eligibilité véhicule
    var eligibiliteVehicule = this.vxlloab.eligibiliteVehicule;

    eligibiliteVehicule = eligibiliteVehicule
      .replace('ageVehicule', this.getAgeVehicule())
      .replace('fiveYears', fiveYears)
      .replace('avecTVA', this.avecTVA)
      .replace(/genre/g, this.genre)
      .replace(/repriseMarchand/g, repriseMarchand);

    if (this.dateImmatriculation === '' || this.dateImmatriculation === null) {
      var errorDescription = [];
      errorDescription['date_immat'] = "non_renseignee";
      return {"error": true, "value": "r", "description": errorDescription};
    }


    if (this.getAgeVehicule() > fiveYears) {
      var errorDescription = [];
      errorDescription['age'] = ">";
      return {"error": true, "value": "r", "description": errorDescription};
    }

    if (eval(eligibiliteVehicule) === false
      || !(this.puissanceFiscale && this.energie && ((this.eligibiliteLoab && this.coeffsVR && repriseMarchand == 0) || (this.elibibiliteDirectDrive == 1  && repriseMarchand == 1)))
    ) {
      return {"error": true, "value": "v"};
    }

    var eligibiliteError = this.checkReglettesEligibilite('vxlloab', repriseMarchand);

    if (eligibiliteError.error === true) {

      return { "error": true, "value": "r", "description": eligibiliteError };
    }

    var taux = this.vxlloab.tauxBase;

    var vrMin = 0;
    var vrMax = 0;

    if (repriseMarchand == 1) {
      var donneesVr = this.getVRLoaBallonDirectDrive();
      var vr = donneesVr['vr'];
      vrMin = Math.ceil(donneesVr['vrMin']);
      vrMax = Math.ceil(donneesVr['vrMax']);

      if (vrSaisie != null) {
        vrSaisie = parseFloat(vrSaisie);
        if (!isNaN(vrSaisie) && vrSaisie >= vrMin && vrSaisie <= vrMax) {
          vr = vrSaisie;
        }
      }
    } else {
      var vr = this.getVRLoaBallon();
    }

    if (vr <= 0)  {
      return {"error": true, "value": "r", "description": true};
    }

    var mensualiteBase = this.calculValeurMensualiteLOA(this.prixVehiculeTTC, this.apport, this.duree, taux, vr)
    var prixOptions = parseFloat(this.calculPrixOptions(optionsSouscrites, apport, 'VXLLOAB'));
    var premierLoyer = parseFloat(this.apport > 0 ? this.apport : mensualiteBase);

    var coeffKm = 0;
    if (repriseMarchand == 1) {
        coeffKm = this.vxlloab.tauxDirectDrive['>15000'].energie[this.energie].puissanceFiscale[this.puissanceFiscale > 11 ? 11 : this.puissanceFiscale];
    } else {
      coeffKm = this.vxlloab.taux[this.kilometrage <= 20000 ? '<=20000' : '>20000'].energie[this.energie].puissanceFiscale[this.puissanceFiscale > 11 ? 11 : this.puissanceFiscale];
    }


    return {
      "error": false,
      "value": mensualiteBase + prixOptions,
      "sansOpt": mensualiteBase,
      "securivie" : isNaN(this.calculVXLBVI()) ? null : parseFloat(this.calculVXLBVI()),
      "securicap" : isNaN(this.calculVXLBCA()) ? null : parseFloat(this.calculVXLBCA()),
      "securicar" : isNaN(this.calculVXLBCR()) ? null : parseFloat(this.calculVXLBCR()),
      "securi4" : isNaN(this.calculVXLBS4()) ? null : parseFloat(this.calculVXLBS4()),
      "premierLoyer": premierLoyer,
      "vr": vr,
      "pourcentVr": vr / this.prixVehiculeTTC * 100,
      "coefKm": coeffKm,
      "premierLoyerAvecOpt": premierLoyer + prixOptions,
      "prixOptions": prixOptions,
      "vrMin" : vrMin,
      "vrMax" : vrMax
    };
  }

  getVRLoaBallon()
  {
    var puissanceFiscaleVR = this.puissanceFiscale > 11 ? 11 : this.puissanceFiscale;
    var coeffKilometre = this.vxlloab.taux[this.kilometrage <= 20000 ? '<=20000' : '>20000'].energie[this.energie].puissanceFiscale[puissanceFiscaleVR];
    var coeffVR = this.coeffsVR[this.duree];

    var prixKilometresSupp = 0;
    if (this.kilometrage > 10000) {
      var kmTranche1 = 0;
      var kmTranche2 = 0;
      var kmTranche3 = 0;

      if (this.kilometrage < 15000) {
        kmTranche1 = this.kilometrage - 10000;
      } else if (this.kilometrage < 20000) {
        kmTranche1 = 5000;
        kmTranche2 = this.kilometrage - 15000;
      } else {
        kmTranche1 = 5000;
        kmTranche2 = 5000;
        kmTranche3 = this.kilometrage - 20000;
      }

      var taux1 = this.vxlloab.taux['<=15000'];
      var taux2 = this.vxlloab.taux['<=20000'].energie[this.energie].puissanceFiscale[puissanceFiscaleVR];
      var taux3 = this.vxlloab.taux['>20000'].energie[this.energie].puissanceFiscale[puissanceFiscaleVR];

      prixKilometresSupp = ((kmTranche1 * taux1) + (kmTranche2 * taux2) + (kmTranche3 * taux3)) * (this.duree / 12);
    }

    var vr = (this.prixConstructeurTTC * (coeffVR / 100)) - prixKilometresSupp;
    if (vr < this.prixVehiculeTTC * 0.15) {
      return (this.prixVehiculeTTC * 0.15);
    } else {
      return vr;
    }
  }


  getVRLoaBallonDirectDrive()
  {

    // On calcule la VR par défaut (sur 25 mois)
    var puissanceFiscaleVR = this.puissanceFiscale > 11 ? 11 : this.puissanceFiscale;
    var pourcentageVr = 55;

    var vrMin = 0;
    var vrMax = 0;
   if (this.duree >= 25 && this.duree < 37) {
    vrMin = this.prixVehiculeTTCHorsFrais * 0.4;
    vrMax = this.prixVehiculeTTCHorsFrais * 0.63;
  }

    if (this.duree >= 37 && this.duree < 43) {
      vrMin = this.prixVehiculeTTCHorsFrais * 0.3;
      vrMax = this.prixVehiculeTTCHorsFrais * 0.58;
    }

    if (this.duree >= 43 && this.duree < 48) {
      vrMin = this.prixVehiculeTTCHorsFrais * 0.2;
      vrMax = this.prixVehiculeTTCHorsFrais * 0.48;
    }

    if (this.duree >= 48 && this.duree < 60) {
      vrMin = this.prixVehiculeTTCHorsFrais * 0.15;
      vrMax = this.prixVehiculeTTCHorsFrais * 0.44;
    }

    if (this.duree >= 60) {
      vrMin = this.prixVehiculeTTCHorsFrais * 0.15;
      vrMax = this.prixVehiculeTTCHorsFrais * 0.40;
    }

    //dépréciation annuelle
    var depreciationMensuelleTranche1 = ((((this.duree > 36 ? 36 : this.duree) - 25) *  0.5833) );
    var depreciationMensuelleTranche2 = ((((this.duree > 36 ? this.duree : 36) - 36) *  0.8333) );

    var prixKilometresSupp = 0;
    if (this.kilometrage > 10000) {
      var kmTranche1 = 0;
      var kmTranche2 = 0;

      if (this.kilometrage < 15000) {
        kmTranche1 = this.kilometrage - 10000;
      } else {
        kmTranche1 = 5000;
        kmTranche2 = this.kilometrage - 15000;
      }

      var taux1 = this.vxlloab.tauxDirectDrive['<=15000'];
      var taux2 = this.vxlloab.tauxDirectDrive['>15000'].energie[this.energie].puissanceFiscale[puissanceFiscaleVR];


      prixKilometresSupp = ((kmTranche1 * taux1) + (kmTranche2 * taux2) ) * (this.duree / 12);
    }

    var vr = (((pourcentageVr - depreciationMensuelleTranche1 - depreciationMensuelleTranche2)/100) * this.prixVehiculeTTCHorsFrais) - prixKilometresSupp;

    if (vr < vrMin) {
      vr =  vrMin;
    }

    if (vr > vrMax) {
      vr =  vrMax;
    }

    return {"vr" : vr, "vrMin" : vrMin, 'vrMax' : vrMax};
  }

  /*
                 _   (_)
      ___  ____ | |_  _  ___  ____   ___
     / _ \|  _ \|  _)| |/ _ \|  _ \ /___)
    | |_| | | | | |__| | |_| | | | |___ |
     \___/| ||_/ \___)_|\___/|_| |_(___/
          |_|
  */
  calculPrixOptions(optionsSouscrites, apport, produit)
  {
    var totalOptions = 0;

    for (var key in optionsSouscrites[produit]) {
      if (optionsSouscrites[produit][key] == 1) {
        var prixOption = this['calcul' + key]();
        totalOptions += (isNaN(prixOption) ? 0 : prixOption);
      }
    }

    var forfait = 0;

    if (produit == 'VXLVAT') {
      forfait = this.calculForfaitVAT(optionsSouscrites);
    }

    return totalOptions + forfait;
  }

  // ---- VAT ---- //
  calculVXLVVI()
  {
    return this.calculPrixOptionCoeff(
      this.prixVehiculeTTC - this.apport,
      this.vxlvat['options']['VXLVVI']['valeurs']['coef']
    )
  }

  calculVXLVCA()
  {
    return this.calculPrixOptionCoeff(
      this.prixVehiculeTTC - this.apport,
      this.vxlvat['options']['VXLVCA']['valeurs']['coef']
    )
  }

  calculVXLVCR()
  {
    return this.calculOptionSecuricar(this.vxlvat['options']['VXLVCR']['valeurs']);
  }


  calculOptionSecuricar(montants) {
    var ageVehicule = this.getAgeVehicule();
    var etatVehiculeViaxel = this.getEtatVehiculeViaxel();
    var tranchePuissanceDin = this.getTranchePuissanceDin();

    if (this.energieCalculOption == null) {
      return 0;
    }

    if (etatVehiculeViaxel == VIAXEL_VN) {
      if (this.garantieConstructeur == '36' ) {
        return montants[this.energieCalculOption]
            [etatVehiculeViaxel]['garantieConstructeur']['36'];
      } else {
        return montants[this.energieCalculOption]
            [etatVehiculeViaxel]['garantieConstructeur']['24'];
      }
    } else if (etatVehiculeViaxel === VIAXEL_VO) {
      if (this.garantieConstructeurRestante >= 12 || this.agrement1erJour == 0 || ageVehicule>=fiveYears) {
        if (ageVehicule < fiveYears) {
          return montants[this.energieCalculOption]
              [etatVehiculeViaxel]['nbMoisGarantieRestante']['>12']['ageVehicule']['<5']['puissanceDin'][tranchePuissanceDin];
        } else {
          return montants[this.energieCalculOption]
              [etatVehiculeViaxel]['nbMoisGarantieRestante']['>12']['ageVehicule']['>=5']['puissanceDin'][tranchePuissanceDin];
        }
      } else {
        return montants[this.energieCalculOption]
            [etatVehiculeViaxel]['nbMoisGarantieRestante']['<12']['puissanceDin'][tranchePuissanceDin];
      }
    } else {
      return 0;
    }
  }

  getTranchePuissanceDin() {
    if (this.puissanceDin < 80) {
      return '<80';
    }
    if (this.puissanceDin < 100) {
      return '<100';
    }
    if (this.puissanceDin < 150) {
      return '<150';
    }
    if (this.puissanceDin < 200) {
      return '<200';
    }
    if (this.puissanceDin < 400) {
      return '<400';
    }

    return '';
  }

  calculOptionS4(montants) {
    // Segment X = segment pour lequel on ne sait pas faire du S3/S4 (véhicules trop spécifiques)
    if (this.kilometrageVehicule >= 90000 || this.energieCalculOption == null || this.puissanceDin == null) {
      return 0;
    }

    var etatVehiculeViaxel = this.getEtatVehiculeViaxel();
    var tranchePuissanceDin = this.getTranchePuissanceDin();

    var roulage = null;
    if (this.kilometrage <= 10000) {
      roulage = '10000';
    }else if (this.kilometrage <= 20000) {
      roulage = '20000';
    } else if (this.kilometrage <= 30000) {
      roulage = '30000';
    } else {
      roulage = '50000';
    }

    if (etatVehiculeViaxel == VIAXEL_VN) {
      var value = montants[this.energieCalculOption][etatVehiculeViaxel]['roulage'][roulage]['puissanceDin'][tranchePuissanceDin];
    } else if (etatVehiculeViaxel == VIAXEL_VO) {
      var trancheKilometrage = null;
      if (this.kilometrageVehicule < 30000) {
        trancheKilometrage = '30000';
      } else if (this.kilometrageVehicule < 60000) {
        trancheKilometrage = '60000';
      } else {
        trancheKilometrage = '90000';
      }

      var value = montants[this.energieCalculOption][etatVehiculeViaxel][trancheKilometrage]['roulage'][roulage]['puissanceDin'][tranchePuissanceDin];
    } else {
      return 0;
    }

    return value;
  }

  calculOptionS3(montants) {
    // Segment X = segment pour lequel on ne sait pas faire du S3/S4 (véhicules trop spécifiques)
    if (this.kilometrageVehicule >= 90000 || this.energieCalculOption == null || this.puissanceDin == null) {
      return 0;
    }

    var etatVehiculeViaxel = this.getEtatVehiculeViaxel();
    var tranchePuissanceDin = this.getTranchePuissanceDin();

    var roulage = null;
    if (this.kilometrage <= 10000) {
      roulage = '10000';
    }else if (this.kilometrage <= 20000) {
      roulage = '20000';
    } else if (this.kilometrage <= 30000) {
      roulage = '30000';
    } else if (this.kilometrage <= 50000) {
      roulage = '50000';
    } else {
      roulage = '70000';
    }

    if (etatVehiculeViaxel == VIAXEL_VN) {
      var value = montants[this.energieCalculOption][etatVehiculeViaxel]['roulage'][roulage]['puissanceDin'][tranchePuissanceDin];
    } else if (etatVehiculeViaxel == VIAXEL_VO) {
      var trancheKilometrage = null;
      if (this.kilometrageVehicule < 30000) {
        trancheKilometrage = '30000';
      } else if (this.kilometrageVehicule < 60000) {
        trancheKilometrage = '60000';
      } else {
        trancheKilometrage = '90000';
      }

      var value = montants[this.energieCalculOption][etatVehiculeViaxel][trancheKilometrage]['roulage'][roulage]['puissanceDin'][tranchePuissanceDin];
    } else {
      return 0;
    }

    return value;
  }


  calculVXLVS4()
  {
    return this.calculOptionS4(this.vxlvat['options']['VXLVS4']['valeurs']);

  }

  // ---- LOA ---- //
  calculVXLLVI()
  {
    return this.calculPrixOptionCoeff(
      this.prixVehiculeTTC,
      this.vxlloa['options']['VXLLVI']['valeurs']['coef']
    )
  }
  calculVXLLCA()
  {
    return this.calculPrixOptionCoeff(
      this.prixVehiculeTTC,
      this.vxlloa['options']['VXLLCA']['valeurs']['coef']
    )
  }
  calculVXLLCR()
  {
    return this.calculOptionSecuricar(this.vxlloa['options']['VXLLCR']['valeurs']);
  }
  calculVXLLS4()
  {
    return this.calculOptionS4(this.vxlloa['options']['VXLLS4']['valeurs']);
  }

  // ---- LOAB ---- //
  calculVXLBVI()
  {
    return this.calculVXLLVI();
  }
  calculVXLBCA()
  {
    return this.calculVXLLCA();
  }
  calculVXLBCR()
  {
    return this.calculVXLLCR();
  }
  calculVXLBS4()
  {
    return this.calculVXLLS4();
  }

  // PROVAT
  calculPROVVI()
  {
    return this.calculPrixOptionCoeff(
      this.prixVehiculeTTC - this.apport,
      this.provat['options']['PROVVI']['valeurs']['coef']
    );
  }
  calculPROVCA()
  {
    return this.calculPrixOptionCoeff(
      this.prixVehiculeTTC - this.apport,
      this.provat['options']['PROVCA']['valeurs']['coef']
    );
  }
  calculPROVCR()
  {
    return this.calculOptionSecuricar(this.provat['options']['PROVCR']['valeurs']);
  }
  calculPROVS3()
  {
    return this.calculOptionS3(this.provat['options']['PROVS3']['valeurs']);
  }

  // PROLOA
  calculPROLVI()
  {
    return this.calculPrixOptionCoeff(
      this.prixVehiculeTTC,
      this.proloa['options']['PROLVI']['valeurs']['coef']
    );
  }
  calculPROLCA()
  {
    return this.calculPrixOptionCoeff(
      this.prixVehiculeTTC,
      this.proloa['options']['PROLCA']['valeurs']['coef']
    );
  }
  calculPROLCR()
  {
   return this.calculOptionSecuricar(this.proloa['options']['PROLCR']['valeurs']);
  }
  calculPROLS3()
  {
    return this.calculOptionS3(this.proloa['options']['PROLS3']['valeurs']);
  }


  // ---- PRO LOAB ---- //
  calculPROLBVI()
  {
    return this.calculPROLVI();
  }
  calculPROLBCA()
  {
    return this.calculPROLCA();
  }
  calculPROLBCR()
  {
    return this.calculPROLCR();
  }
  calculPROLBS3()
  {
    return this.calculPROLS3();
  }




  calculForfaitVAT(optionsSouscrites)
  {
    if (optionsSouscrites['VXLVAT']['VXLVVI'] == 1 || optionsSouscrites['VXLVAT']['VXLVCA'] == 1) {
      return this.vxlvat['options']['VXLVVI']['valeurs']['forfait'];
    } else {
      return 0;
    }
  }

  calculPrixOptionCoeff(valeur, coeff)
  {
    return valeur * coeff;
  }

  checkReglettesEligibilite(financementType, repriseMarchand = 0) {
    var errorDescription = [];

    var eligibiliteMensualiteBasse = this[financementType].eligibiliteMensualiteBasse.replace(/mensualite/g, this.duree).replace(/repriseMarchand/g, repriseMarchand);
    var eligibiliteMensualiteHaute = this[financementType].eligibiliteMensualiteHaute.replace(/mensualite/g, this.duree).replace(/repriseMarchand/g, repriseMarchand);
    var eligibiliteApport = this[financementType].eligibiliteApport.replace('apport', this.apport).replace('prixVehiculeTTC', this.prixVehiculeTTC);

    if (typeof this[financementType].eligibiliteKilometrage !== 'undefined' ) {
      var eligibiliteKilometrage = this[financementType].eligibiliteKilometrage.replace('kilometrageTotal', this.kilometrageTotal).replace(/repriseMarchand/g, repriseMarchand);
      if (eval(eligibiliteKilometrage) === false) {
        errorDescription['kilometrage'] = ">";
        errorDescription.error = true;
      }
    }

    if (eval(eligibiliteMensualiteBasse) === false) {
      errorDescription['durée'] = "<";
      errorDescription.error = true;
    }
    if (eval(eligibiliteMensualiteHaute) === false) {
      errorDescription['durée'] = ">";
      errorDescription.error = true;
    }
    if (eval(eligibiliteApport) === false) {
      errorDescription['apport'] = ">";
      errorDescription.error = true;
    }

    return errorDescription;
  }

  getEtatVehiculeViaxel()
  {
    const kmMax = 10000;

    if (this.etatVehiculeViaxel === null) {
      var ageVehicule = this.getAgeVehicule();

      if (ageVehicule < sixMonths && this.kilometrageVehicule < kmMax && this.garantieConstructeur >= 24) {
        this.etatVehiculeViaxel = VIAXEL_VN;
      } else if (ageVehicule < sevenYears) {
        this.etatVehiculeViaxel = VIAXEL_VO;
      } else {
        this.etatVehiculeViaxel = false;
      }
    }
    return this.etatVehiculeViaxel;
  }

  getAgeVehicule()
  {
    var regexDate = /(\d+)(-|\/)(\d+)(?:-|\/)(?:(\d+)\s+(\d+):(\d+)(?::(\d+))?(?:\.(\d+))?)?/;
    if (this.dateImmatriculation && regexDate.test(this.dateImmatriculation)) {
      var now = (+new Date())/1000;
      var engDate = this.dateImmatriculation.substr(6, 4)
        + '-' + this.dateImmatriculation.substr(3, 2)
        + '-' + this.dateImmatriculation.substr(0, 2);
      var dateImmat = (+new Date(engDate))/1000;
      var diff = now - dateImmat;
    } else {
      var diff = 0;
    }
    return diff;
  }

  getNonAvailableOptions()
  {
    if (this.optionsNonEligibles === null) {
      var hasSegmentViaxel = (this.segmentViaxel && this.segmentViaxel.length > 0 && segmentsViaxel.indexOf(this.segmentViaxel) > -1) ? true : false;
      var etatVehiculeViaxel = this.getEtatVehiculeViaxel();
      this.optionsNonEligibles = [];

      if (eval(
        this.vxlvat['options']['VXLVCR']['eligibiliteVehicule']
          .replace('ageVehicule', this.getAgeVehicule())
          .replace('puissanceDin', this.puissanceDin)
          .replace('sevenYears', sevenYears)
          .replace(/etatVehiculeViaxel/g, etatVehiculeViaxel)
          .replace('hasSegmentViaxel', hasSegmentViaxel)
          .replace('kilometrageVehicule', this.kilometrageVehicule)
        ) === false
      ) {
        this.optionsNonEligibles.push('VXLVCR');
      }

      if (eval(
        this.vxlvat['options']['VXLVS4']['eligibiliteVehicule']
          .replace('ageVehicule', this.getAgeVehicule())
          .replace('kilometrageVehicule', this.kilometrageVehicule)
          .replace('fiveYears', fiveYears)
          .replace('puissanceDin', this.puissanceDin)
          .replace('hasSegmentViaxel', hasSegmentViaxel)
            .replace('agrementS3S4', this.agrementS3S4)
          .replace(/genre/g, this.genre)
        ) === false
      ) {
        this.optionsNonEligibles.push('VXLVS4');
      }

      if (eval(
        this.vxlloa['options']['VXLLCR']['eligibiliteVehicule']
          .replace('ageVehicule', this.getAgeVehicule())
          .replace('fiveYears', fiveYears)
          .replace(/etatVehiculeViaxel/g, etatVehiculeViaxel)
          .replace('hasSegmentViaxel', hasSegmentViaxel)
          .replace('puissanceDin', this.puissanceDin)
          .replace('kilometrageVehicule', this.kilometrageVehicule)
        ) === false
      ) {
        this.optionsNonEligibles.push('VXLLCR');
        this.optionsNonEligibles.push('VXLBCR');
      }

      if (eval(
        this.vxlloa['options']['VXLLS4']['eligibiliteVehicule']
          .replace('ageVehicule', this.getAgeVehicule())
          .replace('kilometrageVehicule', this.kilometrageVehicule)
          .replace('fiveYears', fiveYears)
          .replace('hasSegmentViaxel', hasSegmentViaxel)
          .replace('agrementS3S4', this.agrementS3S4)
          .replace('puissanceDin', this.puissanceDin)
          .replace(/genre/g, this.genre)
        ) === false
      ) {
        this.optionsNonEligibles.push('VXLLS4');
        this.optionsNonEligibles.push('VXLBS4');
      }

      if (eval(
        this.provat['options']['PROVCR']['eligibiliteVehicule']
          .replace('ageVehicule', this.getAgeVehicule())
          .replace('sevenYears', sevenYears)
          .replace(/etatVehiculeViaxel/g, etatVehiculeViaxel)
          .replace('kilometrageVehicule', this.kilometrageVehicule)
          .replace('hasSegmentViaxel', hasSegmentViaxel)
          .replace('puissanceDin', this.puissanceDin)
        ) === false
      ) {
        this.optionsNonEligibles.push('PROVCR');
        this.optionsNonEligibles.push('PROLCR');
      }

      if (eval(
        this.provat['options']['PROVS3']['eligibiliteVehicule']
          .replace('ageVehicule', this.getAgeVehicule())
          .replace('kilometrageVehicule', this.kilometrageVehicule)
          .replace('fiveYears', fiveYears)
          .replace('agrementS3S4', this.agrementS3S4)
          .replace('hasSegmentViaxel', hasSegmentViaxel)
          .replace('puissanceDin', this.puissanceDin)
        ) === false
      ) {
        this.optionsNonEligibles.push('PROVS3');
        this.optionsNonEligibles.push('PROLS3');
      }

      /*if (this.garantieConstructeur != 24 && this.garantieConstructeur != 36) {
        this.optionsNonEligibles.push('VXLVCR');
        this.optionsNonEligibles.push('VXLLCR');
        this.optionsNonEligibles.push('VXLBCR');
        this.optionsNonEligibles.push('PROVCR');
        this.optionsNonEligibles.push('PROLCR');
      }*/
    }

    return this.optionsNonEligibles;
  }

  getDefaultApportVAT() {
    if (this.genre == 'VU' || this.genre == 'CTTE' || this.genre == 'DERIVE VP') {
      return this.prixVehiculeTTC / (1+(TAUX_TVA)) * TAUX_TVA;
    } else {
      return this.prixVehiculeTTC * VAT_TAUX_APPORT_BASE;
    }
  }

  getDefaultApportLOA() {
    if (this.origine == ORIGIN_PICKUP) {
      return this.prixVehiculeTTC * LOA_TAUX_APPORT_BASE;
    }

    if (this.genre == 'VU' || this.genre == 'CTTE' || this.genre == 'DERIVE VP') {
      return this.prixVehiculeTTC / (1+(TAUX_TVA)) * TAUX_TVA;
    } else {
      return this.prixVehiculeTTC * LOA_TAUX_APPORT_BASE;
    }
  }

  setPrixVehicule(prixVehiculeTTC) {
    this.prixVehiculeTTC = parseFloat(prixVehiculeTTC);
  }

  // Helper somme d'arrondis
  getRoundSum(arr) {
    var that = this;
    return arr.reduce(function(a,b){
      a = that.roundFloat(a);
      b = that.roundFloat(b);

      return that.roundFloat(a + b);
    }, 0);
  }

  roundFloat(elem) {
    return Math.round(elem * 100) / 100;
  };
}
